import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ReactComponent as ReactLogo } from "../../Assets/Images/long-arrow-alt-right.svg";
import img1 from "../../Assets/Images/slideItem1.png";
import "./Home.css";
import News from "../../Components/News/News";
import { useHistory } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const imageFetch = async (news, setter) => {
	if(news.images[0]) {
		fetch(`${process.env.REACT_APP_API_ENDPOINT}articles/image/${news?.images[0].imageName}`, {
			headers: new Headers({
				"ngrok-skip-browser-warning": "any",
			  })
		}).then(res => res.blob()).then(
			(blob) => {
				setter(URL.createObjectURL(blob));
			})
	}
	else {
		setter(null)
	}
}

const HomePage = (props) => {
	const [news, setNews] = useState([]);
	const [boxingNews, setBoxingNews] = useState([]);
	const [boxeoNews, setBoxeoNews] = useState([]);
	const [womenNews, setWomenNews] = useState([]);
	const [pflNews, setPflNews] = useState([]);
	const [pbcNews, setPbcNews] = useState([]);
	const [belNews, setBelNews] = useState([]);
	const [mmaNews, setMmaNews] = useState([]);
	const [ufcNews, setUfcNews] = useState([]);
	const [trNews, setTrNews] = useState([]);
	const [image, setImage] = useState({});
	const [boxingImage, setBoxingImage] = useState({});
	const [mmaImage, setMmaImage] = useState({});
	const [ufcImage, setUfcImage] = useState({});
	const [trImage, setTrImage] = useState({});
	const [pflImage, setPflImage] = useState({});
	const [belImage, setBelImage] = useState({});
	const [pbcImage, setPbcImage] = useState({});
	const [boxeoImage, setBoxeoImage] = useState({});
	const [womenImage, setWomenImage] = useState({});
	const [video, setVideo] = useState("");
	const history = useHistory();

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_ENDPOINT}articles`, {
			headers: new Headers({
				"ngrok-skip-browser-warning": "any",
			  })
		})
			.then((res) => res.json())
			.then(
				(result) => {
					const mainNews = result.find((item) => item.isMain) || result[0];
					const boxingNews = result.find((item) => item.tags?.toLowerCase().includes('boxing')) || null;
					const boxeoNews = result.find((item) => item.tags?.toLowerCase().includes('boxeo')) || null;
					const pflNews = result.find((item) => item.tags?.toLowerCase().includes('pfl')) || null;
					const ufcNews = result.find((item) => item.tags?.toLowerCase().includes('ufc')) || null;
					const trNews = result.find((item) => item.tags?.toLowerCase().includes('top rank')) || null;
					const pbcNews = result.find((item) => item.tags?.toLowerCase().includes('pbc')) || null;
					const belNews = result.find((item) => item.tags?.toLowerCase().includes('bellator') || item.title?.toLowerCase().includes('bellator')) || null;
					const mmaNews = result.find((item) => item.tags?.toLowerCase().includes('mma')) || null;
					const womenNews = result.find((item) => item.tags?.toLowerCase().includes('women')) || null;
					setNews(mainNews);
					setBoxingNews(boxingNews);
					setBoxeoNews(boxeoNews);
					setPflNews(pflNews);
					setPbcNews(pbcNews);
					setBelNews(belNews);
					setMmaNews(mmaNews);
					setWomenNews(womenNews);
					setUfcNews(ufcNews);
					setTrNews(trNews);
					if(mainNews.youtube) {
						setVideo(mainNews.youtube.replace("watch?v=", "embed/"))
					}
					imageFetch(mainNews, setImage)
					imageFetch(boxingNews, setBoxingImage)
					imageFetch(mmaNews, setMmaImage)
					imageFetch(boxeoNews, setBoxeoImage)
					imageFetch(pbcNews, setPbcImage)
					imageFetch(belNews, setBelImage)
					imageFetch(womenNews, setWomenImage)
					imageFetch(pflNews, setPflImage)
					imageFetch(ufcNews, setUfcImage)
					imageFetch(trNews, setTrImage)
				},
				(error) => {
					console.log(error);
				}
			);
	}, []);

	return (
		<div className="container">
			<Tabs className="tabs-padding">
				<TabList>
					<Tab>Latest</Tab>
					<Tab>MMA</Tab>
					<Tab>Boxing</Tab>
				</TabList>

				<TabPanel>
					<Fragment>
						<div className="homeContent">
							<div className="container">
								<div className="row">
									<div className="col-lg-8 col-xs-12 leftBar">
										<h1>{news?.title || ""}</h1>
										<div className="line-clamp">
											{!!news && (
												<div
													dangerouslySetInnerHTML={{
														__html: news.description,
													}}
												/>
											)}
										</div>
										<button
											type="button"
											className="btn"
											onClick={() =>
												history.push(`/news/${news?.id || 1}`)
											}
										>
											read More
											<ReactLogo className="moreBtn" />
										</button>
									</div>
									<div style={{"padding-top" : "20px"}}>
									{
										video ? 
											<iframe width="1200px" height="600px"
												src={video}>
											</iframe>
											:
										<img
											src={image || img1}
											style={{ width: "100%", display: image ? 'block' : 'none' }}
										/>
									}
									</div>
								</div>
							</div>
						</div>	
					</Fragment>
				</TabPanel>
				<TabPanel>
					<Tabs style={{"margin-top": "15px", "color": "white", "background-color": "black"}}>
						<TabList>
							<Tab>PFL</Tab>
							<Tab>UFC</Tab>
							<Tab>Bellator</Tab>
						</TabList>
						<TabPanel>
							<Fragment>
								<div className="homeContent">
									<div className="container">
										<div className="row">
											<div className="col-lg-8 col-xs-12 leftBar">
												<h1>{pflNews?.title || ""}</h1>
												<div className="line-clamp">
													{!!pflNews && (
														<div
															dangerouslySetInnerHTML={{
																__html: pflNews.description,
															}}
														/>
													)}
												</div>
												<button
													type="button"
													className="btn"
													onClick={() =>
														history.push(`/news/${pflNews?.id || 1}`)
													}
												>
													read More
													<ReactLogo className="moreBtn" />
												</button>
											</div>
											<div style={{"padding-top" : "20px"}}>
											{
												video ? 
													<iframe width="1200px" height="600px"
														src={video}>
													</iframe>
													:
												<img
													src={pflImage || img1}
													style={{ width: "100%", display: pflImage ? 'block' : 'none' }}
												/>
											}
											</div>
										</div>
									</div>
								</div>	
							</Fragment>
						</TabPanel>
						<TabPanel>
							<Fragment>
								<div className="homeContent">
									<div className="container">
										<div className="row">
											<div className="col-lg-8 col-xs-12 leftBar">
												<h1>{ufcNews?.title || ""}</h1>
												<div className="line-clamp">
													{!!ufcNews && (
														<div
															dangerouslySetInnerHTML={{
																__html: ufcNews.description,
															}}
														/>
													)}
												</div>
												<button
													type="button"
													className="btn"
													onClick={() =>
														history.push(`/news/${ufcNews?.id || 1}`)
													}
												>
													read More
													<ReactLogo className="moreBtn" />
												</button>
											</div>
											<div style={{"padding-top" : "20px"}}>
											{
												video ? 
													<iframe width="1200px" height="600px"
														src={video}>
													</iframe>
													:
												<img
													src={ufcImage || img1}
													style={{ width: "100%", display: ufcImage ? 'block' : 'none' }}
												/>
											}
											</div>
										</div>
									</div>
								</div>	
							</Fragment>
						</TabPanel>
						<TabPanel>
							<Fragment>
								<div className="homeContent">
									<div className="container">
										<div className="row">
											<div className="col-lg-8 col-xs-12 leftBar">
												<h1>{belNews?.title || ""}</h1>
												<div className="line-clamp">
													{!!belNews && (
														<div
															dangerouslySetInnerHTML={{
																__html: belNews.description,
															}}
														/>
													)}
												</div>
												<button
													type="button"
													className="btn"
													onClick={() =>
														history.push(`/news/${belNews?.id || 1}`)
													}
												>
													read More
													<ReactLogo className="moreBtn" />
												</button>
											</div>
											<div style={{"padding-top" : "20px"}}>
											{
												video ? 
													<iframe width="1200px" height="600px"
														src={video}>
													</iframe>
													:
												<img
													src={belImage || img1}
													style={{ width: "100%", display: belImage ? 'block' : 'none' }}
												/>
											}
											</div>
										</div>
									</div>
								</div>	
							</Fragment>
						</TabPanel>
					</Tabs>
				</TabPanel>
				<TabPanel>
					<Tabs style={{"margin-top": "15px", "color": "white", "background-color": "black"}}>
						<TabList>
							<Tab>PBC</Tab>
							<Tab>BOXEO</Tab>
							<Tab>Top Rank</Tab>
							<Tab>WOMEN</Tab>
						</TabList>
						<TabPanel>
							<Fragment>
								<div className="homeContent">
									<div className="container">
										<div className="row">
											<div className="col-lg-8 col-xs-12 leftBar">
												<h1>{pbcNews?.title || ""}</h1>
												<div className="line-clamp">
													{!!pbcNews && (
														<div
															dangerouslySetInnerHTML={{
																__html: pbcNews.description,
															}}
														/>
													)}
												</div>
												<button
													type="button"
													className="btn"
													onClick={() =>
														history.push(`/news/${pbcNews?.id || 1}`)
													}
												>
													read More
													<ReactLogo className="moreBtn" />
												</button>
											</div>
											<div style={{"padding-top" : "20px"}}>
											{
												video ? 
													<iframe width="1200px" height="600px"
														src={video}>
													</iframe>
													:
												<img
													src={pbcImage || img1}
													style={{ width: "100%", display: pbcImage ? 'block' : 'none' }}
												/>
											}
											</div>
										</div>
									</div>
								</div>	
							</Fragment>
						</TabPanel>
						<TabPanel>
							<Fragment>
								<div className="homeContent">
									<div className="container">
										<div className="row">
											<div className="col-lg-8 col-xs-12 leftBar">
												<h1>{boxeoNews?.title || ""}</h1>
												<div className="line-clamp">
													{!!boxeoNews && (
														<div
															dangerouslySetInnerHTML={{
																__html: boxeoNews.description,
															}}
														/>
													)}
												</div>
												<button
													type="button"
													className="btn"
													onClick={() =>
														history.push(`/news/${boxeoNews?.id || 1}`)
													}
												>
													read More
													<ReactLogo className="moreBtn" />
												</button>
											</div>
											<div style={{"padding-top" : "20px"}}>
											{
												video ? 
													<iframe width="1200px" height="600px"
														src={video}>
													</iframe>
													:
												<img
													src={boxeoImage || img1}
													style={{ width: "100%", display: boxeoImage ? 'block' : 'none' }}
												/>
											}
											</div>
										</div>
									</div>
								</div>	
							</Fragment>
						</TabPanel>
						<TabPanel>
							<Fragment>
								<div className="homeContent">
									<div className="container">
										<div className="row">
											<div className="col-lg-8 col-xs-12 leftBar">
												<h1>{trNews?.title || ""}</h1>
												<div className="line-clamp">
													{!!trNews && (
														<div
															dangerouslySetInnerHTML={{
																__html: trNews.description,
															}}
														/>
													)}
												</div>
												<button
													type="button"
													className="btn"
													onClick={() =>
														history.push(`/news/${trNews?.id || 1}`)
													}
												>
													read More
													<ReactLogo className="moreBtn" />
												</button>
											</div>
											<div style={{"padding-top" : "20px"}}>
											{
												video ? 
													<iframe width="1200px" height="600px"
														src={video}>
													</iframe>
													:
												<img
													src={trImage || img1}
													style={{ width: "100%", display: trImage ? 'block' : 'none' }}
												/>
											}
											</div>
										</div>
									</div>
								</div>	
							</Fragment>
						</TabPanel>
						<TabPanel>
							<Fragment>
								<div className="homeContent">
									<div className="container">
										<div className="row">
											<div className="col-lg-8 col-xs-12 leftBar">
												<h1>{womenNews?.title || ""}</h1>
												<div className="line-clamp">
													{!!womenNews && (
														<div
															dangerouslySetInnerHTML={{
																__html: womenNews.description,
															}}
														/>
													)}
												</div>
												<button
													type="button"
													className="btn"
													onClick={() =>
														history.push(`/news/${womenNews?.id || 1}`)
													}
												>
													read More
													<ReactLogo className="moreBtn" />
												</button>
											</div>
											<div style={{"padding-top" : "20px"}}>
											{
												video ? 
													<iframe width="1200px" height="600px"
														src={video}>
													</iframe>
													:
												<img
													src={womenImage || img1}
													style={{ width: "100%", display: womenImage ? 'block' : 'none' }}
												/>
											}
											</div>
										</div>
									</div>
								</div>	
							</Fragment>
						</TabPanel>
					</Tabs>
				</TabPanel>
			</Tabs>
			<News image={image}/>
		</div>
	);
};

export default HomePage;

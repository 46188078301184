import React from "react";
import headerlogo from "../../Assets/Images/headerlogo.png";
import "./AdvBlockSubscribe.css";

const AdvBlockSubscribe = () => {
	return (
		<div className="adv-blocksubscribe">
			<div className="adv-blocksubscribe-wrapper">
				<div className="adv-blocksubscribe-logowrapper">
					<h2 className="adv-logo text-white">
						<img src={headerlogo} width="90" />
						<span className="font-weight-light">
							<span className="font-weight-bold">FIGHT</span>NEWS
						</span>
					</h2>
				</div>

				<div className="adv-blocksubscribe-contentwrapper">
					<h2 className="adv-blocksubscribe-content">
						SUBSCRIBE TO OUR NEWSLETTER
					</h2>
					<button className="btn adv-subscribe-btn">subscribe</button>
				</div>
			</div>
		</div>
	);
};

export default AdvBlockSubscribe;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import HorizontalAdvertisement from "../../Components/HorizontalAdvertisement";
import AddComment from "../../Components/AddComment";

import img1 from "../../Assets/Images/defaultImage.jpg";
import {
	faShare
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./NewsDetail.css";


const defaultValues = {
	author: "",
	message: "",
};

const NewsDetail = () => {
	const { id } = useParams();
	const [data, setData] = useState(false);
	const [image, setImage] = useState("");
	const [video, setVideo] = useState("");
	const [shareText, setShareText] = useState("Share Article");

	useEffect(() => {
		//ajax call for get news data:
		//params.id is news id
		fetch(`${process.env.REACT_APP_API_ENDPOINT}articles/by-id/${id}`, {
			headers: new Headers({
				"ngrok-skip-browser-warning": "any",
			  })
		})
			.then((res) => res.json())
			.then(
				(result) => {
					console.log(result);
					setData(result);
					setTimeout(() => {
						const iframe = document.getElementById('estframe')
						if(iframe) {
							iframe.style.height = '1000px';
							iframe.style.width = '2000px';
							iframe.style.marginLeft = '-200px';
						}
					}, 2000);
					if(result.youtube) {
						setVideo(result.youtube.replace("watch?v=", "embed/"))
					}
					if(!result.images[0]) {
						return
					}
					fetch(`${process.env.REACT_APP_API_ENDPOINT}articles/image/${result.images[0].imageName}`, {
						headers: new Headers({
							"ngrok-skip-browser-warning": "any",
						  })
					}).then(res => res.blob()).then(
						(blob) => {
							setImage(URL.createObjectURL(blob));
						})
				},
				// Note: it's important to handle errors here
				// instead of a catch() block so that we don't swallow
				// exceptions from actual bugs in components.
				(error) => {
					console.log(error);
				}
			);
	}, [id]);

	const addComment = (data) => {
		if (!!data.author && !!data.message) {
			data.articleId = id;
			fetch(`${process.env.REACT_APP_API_ENDPOINT}comment`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"ngrok-skip-browser-warning": "any",
					// 'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: JSON.stringify(data),
			})
				.then((res) => res.json())
				.then(
					(result) => {
						console.log(result);
						setData((prevState) => {
							const newState = {
								...prevState,
								comments: [...prevState.comments, result],
							};
							return newState;
						});
						//reset(defaultValues);
						//setData(result);
					},
					// Note: it's important to handle errors here
					// instead of a catch() block so that we don't swallow
					// exceptions from actual bugs in components.
					(error) => {
						console.log(error);
					}
				);
		}
	};

	const copyToClipboard = str => {
		if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
			setShareText("Article Link Copied!")
			setTimeout(() => setShareText("Share Article"), 2000);
			return navigator.clipboard.writeText(str);
		}
		return Promise.reject('The Clipboard API is not available.');
	  };

	return (
		<div className="secondContent">
			<div className="container">
				<div className="secondBar">
					<div className="contentTo">
						<div style={{"color":"white","cursor":"pointer","padding-bottom":"20px"}} onClick={(() => copyToClipboard(window.location))}>
							{shareText}
							<FontAwesomeIcon
								style={{"width":"50px","cursor":"pointer","margin-left":"-10px"}}
								icon={faShare}
								onClick={(() => copyToClipboard(window.location))}
							/>
						</div>
						<h1>{data.title}</h1>
					</div>
					<div className="secondCover">
						<div className="secondTitle">
							{
								video ? 
									<iframe width="100%" height="600px"
										src={video}>
									</iframe>
									:
									<img
										key={image.id}
										src={image || img1}
										height="100%"
										width="100%"
										className="detailImg"
									/>
								}
						</div>
					</div>
				</div>
			</div>

			<HorizontalAdvertisement />

			<div className="container">
				<div className="newsDetailDiscription">
					{/* <p>{data.description}</p> */}
					<div
						dangerouslySetInnerHTML={{
							__html: data.description?.replace(/dl=0/g, 'raw=1" class="dropbox-images"'),
						}}
					/>
				</div>
			</div>

			<AddComment addComment={addComment} comments={data.comments} />
		</div>
	);
};

export default NewsDetail;

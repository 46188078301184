import React from "react";
import "./Footer.css";
import BrandLogo from "../../Assets/Images/BrandLogo.png";
import {
	faInstagram,
	faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Footer() {
	return (
		<>
			<div className="footer">
				<div className="container d-flex justify-content-between">
					<div className="contactUs">
						<h3>Contact us </h3>
						<h5>tko@fight.news</h5>
					</div>
					<div className="follow ">
						<h3>follow</h3>
						<FontAwesomeIcon
							className="footerIcon p-2"
							icon={faInstagram}
							onClick={(() => window.open("https://www.instagram.com/fightnewscorps/?hl=en"))}
						/>
						<FontAwesomeIcon
							className="footerIcon p-2  "
							icon={faYoutube}
							onClick={(() => window.open("https://www.youtube.com/@FightCorps"))}
						/>
					</div>
					<div className="subscribe">
						<h3>Subscribe via Email</h3>
						<form>
							<input type="email" placeholder="Email Address" />
							<input type="submit" value="SUBSCRIBE" />
						</form>
					</div>
				</div>
			</div>
			<div className="container lastDiv">
				<img src={BrandLogo} height="35px" />
				<span>2023 All rights reserved</span>
			</div>
		</>
	);
}

export default Footer;

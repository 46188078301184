import React from "react";

import "./AdvBlockDownload.css";

const AdvBlockDownload = () => {
	return (
		<div className="adv-blockdownload">
			<div className="adv-blockdownload-wrapper">
				<div className="adv-blockdownload-logowrapper">
					<div className="adv-blockdownload-logo-content">
						<h3>10% sale</h3>
						<p>FOR DOWNLOADING</p>
					</div>
				</div>
				<div className="adv-blockdownload-contentwrapper">
					<button className="btn adv-download-btn">download</button>
				</div>
			</div>
		</div>
	);
};

export default AdvBlockDownload;

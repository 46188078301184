import React from "react";
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFacebookF,
	faInstagram,
	faYoutube,
} from "@fortawesome/free-brands-svg-icons";

import userIcon from "../../Assets/Images/user1.png";

import "./AddComment.css";

const defaultValues = {
	author: "",
	message: "",
};

const AddComment = ({ addComment, comments }) => {
	const { register, handleSubmit, reset, errors } = useForm({
		defaultValues,
	});

	const onSubmit = (data) => {
		console.log(data);
		addComment(data);
		reset(defaultValues);
	};

	return (
		<div className="container-fluid">
			<div className="row justify-content-center">
				<div className="col-xl-10">
					<div className="comment-wrapper">
						<div className="row no-gutters mb-4">
							<div className="col-lg-8">
								<div className="comment-left-block">
									<div className="row">
										<div className="col-lg-9">
											<h3 className="comment-form-title text-black mb-30">
												Leave a comment
											</h3>
											<form
												onSubmit={handleSubmit(
													onSubmit
												)}
											>
												<div className="form-group mb-30">
													<input
														type="text"
														className={classNames(
															"form-control",
															{
																"is-invalid":
																	errors.author,
															}
														)}
														placeholder="Name"
														name="author"
														ref={register({
															required: true,
														})}
													/>
												</div>
												<div className="form-group mb-20">
													<textarea
														type="text"
														className={classNames(
															"form-control",
															{
																"is-invalid":
																	errors.author,
															}
														)}
														placeholder="Comment"
														rows="6"
														name="message"
														ref={register({
															required: true,
														})}
													/>
												</div>

												<div className="text-right">
													<button className="btn add-coment-btn">
														SUBMIT
													</button>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="comment-right-block">
									<h3 className="comment-form-title text-white mb-30">
										Subscribe
									</h3>
									<form>
										<div className="form-group mb-30">
											<input
												type="email"
												className="form-control"
												placeholder="Email Address"
												style={{
													boxShadow: "none!important",
												}}
											/>
										</div>
										<div className="text-right">
											<button className="btn add-coment-btn">
												SUBSCRIBE
											</button>
										</div>
									</form>

									<h3 className="comment-form-title text-white mb-30">
										Follow us
									</h3>
									<div className="social-icons">
										<a
											href="#"
											className="social-icon-item text-white"
										>
											<FontAwesomeIcon
												icon={faFacebookF}
											/>
										</a>
										<a
											href="#"
											className="social-icon-item text-white"
										>
											<FontAwesomeIcon
												icon={faInstagram}
											/>
										</a>
										<a
											href="#"
											className="social-icon-item text-white"
										>
											<FontAwesomeIcon icon={faYoutube} />
										</a>
									</div>
								</div>
							</div>
						</div>

						<div className="row no-gutters">
							<div className="col-lg-8">
								{comments?.map((comment) => {
									return (
										<div
											className="media mb-3"
											key={comment.id}
										>
											<img
												src={userIcon}
												width="42px"
												className="mr-3"
											/>
											<div className="media-body comRow">
												<h5 className="mt-0">
													<span className="nameComm">
														{comment.author}
													</span>
													<span className="date">
														{(new Date(comment.created)).toDateString()}
													</span>
												</h5>
												<p>{comment.message}</p>
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddComment;

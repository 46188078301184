import React, { useEffect, useState } from "react";

import { ThemeProvider } from "@zendeskgarden/react-theming";
import { Tabs, TabList, Tab, TabPanel } from "@zendeskgarden/react-tabs";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import img1 from "../../Assets/Images/slideItem1.png";

import AdvBlockSubscribe from "../AdvBlockSubscribe";
import AdvBlockDownload from "../AdvBlockDownload";
import { ReactComponent as ReactLogo } from "../../Assets/Images/long-arrow-alt-right.svg";

import "./News.css";

const News = (props) => {
	const history = useHistory();
	const [selectedTab, setSelectedTab] = useState("tab-1");
	const [news, SetNews] = useState([]);
	const [video, setVideo] = useState("");
	const [mainNews, setMainNews] = useState("");
	const [pageNumber, setPageNumber] = useState(0);
	const newsPerPage = 3;
	const pagesVisited = pageNumber * newsPerPage;

	const displayNews = news
		.slice(pagesVisited, pagesVisited + newsPerPage)
		.map((item) => {
			return (
				<div className="newsItem" key={item.id}>
					<div className="newsDecrip">
						<OverlayTrigger
							placement="bottom"
							overlay={
								<Tooltip id={`tooltip-${item.id}`}>
									{item.title}
								</Tooltip>
							}
						>
							<h6>
								{item.title.length > 60
									? `${item.title.substring(0, 60)}...`
									: item.title}
							</h6>
						</OverlayTrigger>

						<div className="moreDiscrip">
							<span className="">
								{moment(item.created).format("MMMM DD, YYYY")}
							</span>
							<span className=" ">
								<Link to={`/news/${item.id}`}>Read More</Link>
							</span>
						</div>
					</div>
				</div>
			);
		});

	const pageCount = Math.ceil(news.length / newsPerPage);

	const changePage = ({ selected }) => {
		setPageNumber(selected);
	};

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_ENDPOINT}articles`, {
			headers: new Headers({
				"ngrok-skip-browser-warning": "any",
			  })
		}).then((res) => res.json())
			.then(
				(result) => {
					const mainNews = result.find((item) => item.isMain) || news[0];
					setMainNews(mainNews)
					if(mainNews?.youtube) {
						setVideo(mainNews.youtube.replace("watch?v=", "embed/"))
					}
					SetNews(result);
				},
				(error) => {
					console.log(error);
				}
			);
	}, []);

	return (
		<div className="newsContent" style={{"background-color": "white"}}>
			<div className="container">
				<div className="row">
					<div className="col-lg-8 col-md-12 col-sm-12 col-xs-12">
						<div className="leftNewsBar">
							<div className="headerBar">
								<h1>News</h1>
							</div>
							{!!mainNews && (
								<div className="newsCover">
									{
										video ? 
											<iframe width="100%" height="600px"
												src={video}>
											</iframe>
											:
										<img
											src={props.image || img1}
											style={{ width: "100%", display: props.image ? 'block' : 'none' }}
										/>
									}
									<div className="newsDiscription">
										<h1>{mainNews.title}</h1>
										<div>
											<div
												dangerouslySetInnerHTML={{
													__html: mainNews.description?.replace(/dl=0/g, 'raw=1" class="dropbox-images"'),
												}}
											></div>
										</div>
									</div>
									<div className="footerBar">
										<span className="newsDate">
											{moment(mainNews.created).format(
												"MMMM DD, YYYY"
											)}
										</span>
										<button
											type="button"
											className="btn"
											onClick={() =>
												history.push(
													`/news/${mainNews.id}`
												)
											}
										>
											read More
											<ReactLogo className="moreBtn" />
										</button>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 rightBars">
						<div className="rightNewsBar mb-4">
							<ThemeProvider>
								<Tabs
									className="newsInline"
									selectedItem={selectedTab}
									onChange={setSelectedTab}
								>
									<TabList className="newsTabs">
										<Tab className="tab-link" item="tab-1">
											ARTICLES
										</Tab>
									</TabList>
									<TabPanel item="tab-1">
										{displayNews}
										<ReactPaginate
											previousLabel={"<"}
											nextLabel={">"}
											pageCount={pageCount}
											onPageChange={changePage}
											marginPagesDisplayed={1}
											pageRangeDisplayed={2}
											containerClassName={
												"paginationBttns"
											}
											previousLinkClassName={
												"previousBttn"
											}
											nextLinkClassName={"nextBttn"}
											disabledClassName={
												"paginationDisabled"
											}
											activeClassName={
												"paginationAcitive"
											}
										/>
									</TabPanel>
								</Tabs>
							</ThemeProvider>
						</div>

						<div className="mb-4">
							<AdvBlockSubscribe />
						</div>

						<div className="mb-4">
							<AdvBlockDownload />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default News;

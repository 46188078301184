import React, { useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./Components/Header/Header";
// import Home from "./Components/Home/Home";
import Footer from "./Components/Footer/Footer";
import HomePage from "./Pages/HomePage";
import YouTube from "./Pages/YouTube";
import NewsDetail from "./Pages/NewsDetail";
import ReactGA from "react-ga";

function initizeAnalytics() {
	ReactGA.initialize("272142154");
	ReactGA.pageview("/");
}

function App() {
	initizeAnalytics();
	return (
		<>
			<Router forceRefresh>
				<Header />
				<Switch>
					<Route path="/" exact>
						<HomePage />
					</Route>
					<Route path="/youtube" exact>
						<YouTube />
					</Route>
					<Route path="/news/:id">
						<NewsDetail />
					</Route>
				</Switch>
				<Footer />
			</Router>
		</>
	);
}

export default App;

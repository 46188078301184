import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./YouTube.css";


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const YouTube = () => {

	return (
		<div className="container">
			<Tabs className="tabs-padding">
				<TabList>
					<Tab>Live Stream</Tab>
					<Tab>Latest Video</Tab>
				</TabList>

				<TabPanel>
					<Fragment>
						<div className="homeContent" style={{"min-height":"80vh"}}>
							<div className="container">
								<div className="video-container">
									<iframe
										src="https://www.youtube-nocookie.com/embed/live_stream?channel=UUbTvgqY5c7sULOTZ7O_Pq_g"
										width="100%"
										height="auto"
										allowfullscreen>
									</iframe>
								</div>
							</div>
						</div>	
					</Fragment>
				</TabPanel>
				<TabPanel>
					<Fragment>
						<div className="homeContent" style={{"min-height":"80vh"}}>
							<div className="container">
								<div className="video-container">
									<iframe
										src="https://www.youtube-nocookie.com/embed?listType=playlist&list=UUbTvgqY5c7sULOTZ7O_Pq_g"
										width="100%"
										height="900"
										allowfullscreen>
									</iframe>
								</div>
							</div>
						</div>	
					</Fragment>
				</TabPanel>
			</Tabs>
		</div>
	);
};

export default YouTube;

import React from "react";
import { Button } from "react-bootstrap";
import "./HorizontalAdvertisement.css";
const HorizontalAdvertisement = () => {
	return (
		<div className="horizontal-adv">
			<div className="horizontal-adv-layer"></div>
			<div className="container-fluid">
				<div className="row justify-content-center">
					<div className="col-xl-11">
						<div className="row">
							<div className="col-md-4 align-self-center">
								<h2 className="adv-subtitle text-white">
									YOUR AD
								</h2>
								<h1 className="adv-title text-firebrick mb-0">
									FIGHT NEWS
								</h1>
							</div>
							<div className="col-md-4 text-center align-self-center">
								<button className="btn adv-btn">
									Read more...
								</button>
							</div>
							<div className="col-md-4 align-self-center">
								<div className="text-right">
									<h2 className="adv-sale font-weight-bold text-white mb-0 mr-5">
										50%
									</h2>
									<h2 className="adv-sale text-firebrick mb-0">
										OFF
									</h2>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default HorizontalAdvertisement;

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Header.css";
import headerlogo from "../../Assets/Images/headerlogo.png";
// import { ReactComponent as ReactLogo1 } from "../../Assets/Images/storeIcon.svg";
// import { ReactComponent as ReactLogo2 } from "../../Assets/Images/shopCart.svg";

function Header() {
    const [navbar, setNavbar] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 120) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    window.addEventListener("scroll", changeBackground);

    return (
        <div className={navbar ? "fixed-top active" : "fixed-top"}>
            <div className="navbar container navbar-expand-lg d-flex align-items-center">
                <NavLink to="/" className="headerLogo">
                    <img src={headerlogo} width="90" height="60" />
                    <span className="leftProp">FIGHT</span>
                    <span className="rightProp">NEWS</span>
                </NavLink>
                <div
                    
                    id="navbarSupportedContent"
                >
                    <ul className="navbar-nav mr-auto">
                        <div style={{"display" : "flex"}}>
                         <li className="nav-item active">
                            <NavLink
                                exact
                                to="/"
                                className="main-nav"
                                activeClassName="main-nav-active"
                            >
                                News
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            { <NavLink
                                to="/youtube"
                                className="main-nav"
                                activeClassName="main-nav-active"
                            >
                                YouTube
                            </NavLink>}
                        </li>
                        <li className="nav-item">
                            <NavLink
                                to="/champions"
                                className="main-nav"
                                activeClassName="main-nav-active"
                            >
                                Champions
                            </NavLink>
                        </li>
                        </div>
                        <li className="nav-item">
                            { <NavLink
                                to="/fights"
                                className="main-nav"
                                activeClassName="main-nav-active"
                            >
                                Upcoming Fights
                            </NavLink>}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Header;
